@import url('https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI');

ul.lst-kix_wv6na3xgpjfx-2 {
    list-style-type: none
}

ul.lst-kix_wv6na3xgpjfx-1 {
    list-style-type: none
}

ul.lst-kix_wv6na3xgpjfx-4 {
    list-style-type: none
}

ul.lst-kix_wv6na3xgpjfx-3 {
    list-style-type: none
}

ul.lst-kix_wv6na3xgpjfx-0 {
    list-style-type: none
}

.lst-kix_wv6na3xgpjfx-1>li:before {
    content: "  "
}

ul.lst-kix_wv6na3xgpjfx-6 {
    list-style-type: none
}

ul.lst-kix_wv6na3xgpjfx-5 {
    list-style-type: none
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_wv6na3xgpjfx-0>li:before {
    content: "  "
}

.lst-kix_wv6na3xgpjfx-8>li:before {
    content: "\0025a0  "
}

ul.lst-kix_wv6na3xgpjfx-8 {
    list-style-type: none
}

ul.lst-kix_wv6na3xgpjfx-7 {
    list-style-type: none
}

.lst-kix_wv6na3xgpjfx-5>li:before {
    content: "\0025a0  "
}

.lst-kix_wv6na3xgpjfx-3>li:before {
    content: "\0025cf  "
}

.lst-kix_wv6na3xgpjfx-7>li:before {
    content: "\0025cb  "
}

.lst-kix_wv6na3xgpjfx-2>li:before {
    content: "\0025a0  "
}

.lst-kix_wv6na3xgpjfx-6>li:before {
    content: "\0025cf  "
}

.lst-kix_wv6na3xgpjfx-4>li:before {
    content: "\0025cb  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c2 {
    border-right-style: solid;
    padding-top: 24px;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    border-top-style: solid;
    background-color: #ffffff;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
}

.c5 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 30px;
    border-top-style: solid;
    margin-left: 72pt;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
}

.c14 {
    border-right-style: solid;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    padding-right: 0pt
}

.c13 {
    color: #0e0e0e;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 22.5pt;
    font-family: "Roboto";
    font-style: normal
}

.c0 {
    color: #0e0e0e;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Roboto";
    font-style: normal
}

.c4 {
    color: #0e0e0e;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Roboto";
    font-style: normal
}

.c12 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.c8 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c9 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-family: "Roboto"
}

.c11 {
    -webkit-text-decoration-skip: none;
    color: #b71e21;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

.c6 {
    font-family: "Roboto";
    color: #0e0e0e;
    font-weight: 400
}

.c15 {
    background-color: #ffffff;
    width: 100%;
    padding: 72px;
}

.c10 {
    font-weight: 400;
    font-family: "Roboto"
}

.c1 {
    padding: 0;
    margin: 0
}

.c7 {
    color: inherit;
    text-decoration: inherit
}

.c3 {
    height: 11pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

@media only screen and (max-width: 767px) {
    .c15 {
        padding: 24px
    }

    .c5,
    .c2 {
        margin-left: 0;
    }
}