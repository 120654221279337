@import url('https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI');

.lst-kix_zgrswlno10cw-3>li:before {
    content: "\0025cf  "
}

.lst-kix_zgrswlno10cw-5>li:before {
    content: "\0025a0  "
}

.lst-kix_zgrswlno10cw-0>li:before {
    content: "  "
}

.lst-kix_zgrswlno10cw-4>li:before {
    content: "\0025cb  "
}

.lst-kix_zgrswlno10cw-1>li:before {
    content: "\0025cb  "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

.lst-kix_zgrswlno10cw-2>li:before {
    content: "\0025a0  "
}

ul.lst-kix_zgrswlno10cw-4 {
    list-style-type: none
}

ul.lst-kix_zgrswlno10cw-5 {
    list-style-type: none
}

ul.lst-kix_zgrswlno10cw-2 {
    list-style-type: none
}

ul.lst-kix_zgrswlno10cw-3 {
    list-style-type: none
}

ul.lst-kix_zgrswlno10cw-8 {
    list-style-type: none
}

ul.lst-kix_zgrswlno10cw-6 {
    list-style-type: none
}

ul.lst-kix_zgrswlno10cw-7 {
    list-style-type: none
}

.lst-kix_zgrswlno10cw-8>li:before {
    content: "\0025a0  "
}

.lst-kix_zgrswlno10cw-7>li:before {
    content: "\0025cb  "
}

ul.lst-kix_zgrswlno10cw-0 {
    list-style-type: none
}

ul.lst-kix_zgrswlno10cw-1 {
    list-style-type: none
}

.lst-kix_zgrswlno10cw-6>li:before {
    content: "\0025cf  "
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.c0 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 24px;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
}

.c11 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    border-top-style: solid;
    background-color: #ffffff;
    margin-left: 36pt;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
}

.c13 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 120.1pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c7 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 166.6pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c9 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 314.4pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c21 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 314.4pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c5 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 331.2pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c26 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 117.3pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c31 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 136.9pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c15 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 331.2pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c23 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 166.6pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c40 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 136.9pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c42 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 136.9pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c30 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 166.6pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c18 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1.5pt;
    width: 167.3pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c24 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 331.2pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c6 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 120.1pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c8 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 117.3pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c20 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 167.3pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c45 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 117.3pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c41 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 120.1pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c28 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1.5pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 314.4pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid;
}

.c19 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #c2c2c2;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #c2c2c2;
    vertical-align: top;
    border-right-color: #c2c2c2;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 167.3pt;
    border-top-color: #c2c2c2;
    border-bottom-style: solid
}

.c3 {
    color: #0e0e0e;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Roboto";
    font-style: normal
}

.c12 {
    color: #b71e21;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Roboto";
    font-style: normal
}

.c16 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 24px;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c37 {
    -webkit-text-decoration-skip: none;
    color: #b71e21;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-family: "Roboto"
}

.c35 {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c14 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
}

.c43 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 22.5pt;
    font-style: normal
}

.c10 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-style: normal
}

.c22 {
    color: #000000;
    font-weight: 400;
    font-family: "Arial"
}

.c4 {
    font-family: "Roboto";
    color: #0e0e0e;
    font-weight: 700
}

.c29 {
    background-color: #ffffff;
    width: 100%;
    padding: 72px
}

.c27 {
    font-family: "Roboto";
    color: #0e0e0e;
    font-weight: 400
}

.c39 {
    color: inherit;
    text-decoration: inherit
}

.c36 {
    padding: 0;
    margin: 0
}

.c34 {
    height: 161.5pt
}

.c38 {
    height: 187.8pt
}

.c44 {
    height: 240.2pt
}

.c17 {
    height: 31.8pt
}

.c33 {
    height: 43.8pt
}

.c46 {
    height: 135.2pt
}

.c2 {
    height: 56.5pt
}

.c32 {
    height: 43pt
}

.c25 {
    height: 11pt
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}