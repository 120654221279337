input[type="range"] {
    -webkit-appearance: none !important /*Needed to reset default slider styles */;
    width: 100%;
    height: 20px;
    background-color: #bbf7d0;
    border: 2px solid #15803d;
    border-radius: 10px;
    margin: auto;
    transition: all 0.3s ease;
}

input[type="range"]:hover{
    background-color: #bbf7d0;
    opacity:0.9;
}

  
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important ;
    width: 30px;
    height: 30px;
    background-color:#15803d;
    /* border-radius: 30px; */
    box-shadow: 0px 0px 3px ;
    transition: all 0.5s ease;
}

input[type="range"]::-webkit-slider-thumb:hover{
    background-color: #15803d;
    opacity:0.8;
}
